import React from "react";

const BrandIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="currentColor"
      {...props}
    >
      <path
        d="M74.7305 37.418H65.5312C65.3789 21.4688 52.3945 8.60156 36.4102 8.60156C26.3906 8.60156 17.5547 13.6641 12.3047 21.3633C21.6445 21.293 34.043 21.2461 39.2109 21.4805C47.9766 21.8789 51.668 30.0586 51.668 30.0586H8.30859C8.29687 30.0938 8.28516 30.1289 8.28516 30.1641H0.046875C0.246094 29.1562 0.492188 28.1602 0.773438 27.1758C1.34766 25.1953 2.07422 23.2852 2.95312 21.457C9.02344 8.76562 21.9844 0 36.9961 0C57.7383 0 74.5547 16.7227 74.7305 37.418Z"
        fill="currentColor"
      />
      <path
        d="M74.7773 45C74.6367 45.7148 74.4727 46.4297 74.2969 47.1328C73.7227 49.3828 72.9375 51.5508 71.9766 53.625C65.9766 66.5273 52.9102 75.4805 37.7344 75.4805C16.8867 75.4805 0 58.582 0 37.7461H8.01562C8.01562 53.8359 21.0586 66.8789 37.1484 66.8789C47.3438 66.8789 56.3086 61.6523 61.5117 53.7188C52.3477 53.7891 40.7812 53.8125 35.8477 53.5898C27.0703 53.1914 23.3906 45.0117 23.3906 45.0117H65.3672V45H74.7773Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BrandIcon;
