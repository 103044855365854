import React from "react";

const ShareIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ float: props.style?.float }}
    >
      <rect width="60" height="60" rx="30" fill="#5956E9" />
      <path
        d="M23.4999 33C24.3746 32.9974 25.2161 32.6653 25.8569 32.07L32.1169 35.647C31.6833 37.3413 32.5594 39.0969 34.1739 39.7692C35.7883 40.4415 37.6516 39.8265 38.5486 38.3253C39.4457 36.824 39.1046 34.8918 37.7476 33.7885C36.3907 32.6852 34.4295 32.7455 33.1429 33.93L26.8829 30.353C26.9485 30.1021 26.9848 29.8443 26.9909 29.585L33.1419 26.07C34.3628 27.1799 36.1943 27.2828 37.5319 26.3167C38.8694 25.3505 39.3473 23.5794 38.6773 22.0716C38.0072 20.5637 36.3725 19.7314 34.759 20.0766C33.1455 20.4217 31.9944 21.85 31.9999 23.5C32.0035 23.7881 32.0428 24.0746 32.1169 24.353L26.4329 27.6C25.5224 26.1914 23.7406 25.6268 22.185 26.2538C20.6294 26.8809 19.7373 28.5233 20.0582 30.1696C20.379 31.8158 21.8227 33.0031 23.4999 33Z"
        fill="white"
      />
    </svg>
  );
};

export default ShareIcon;
