import React from "react";

const GearIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="67"
      height="67"
      viewBox="0 0 67 67"
      fill="currentColor"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.9465 6.36929C32.4444 6.08784 33.0067 5.93994 33.5787 5.93994C34.1506 5.93994 34.7129 6.08784 35.2108 6.36929L56.7713 18.5556C57.2827 18.8447 57.7081 19.2643 58.0041 19.7717C58.3002 20.279 58.4562 20.8559 58.4562 21.4433V45.7411C58.4562 46.3285 58.3002 46.9054 58.0041 47.4127C57.7081 47.9201 57.2827 48.3397 56.7713 48.6287L35.2108 60.8151C34.7129 61.0966 34.1506 61.2445 33.5787 61.2445C33.0067 61.2445 32.4444 61.0966 31.9465 60.8151L10.386 48.6287C9.87465 48.3397 9.4492 47.9201 9.15318 47.4127C8.85716 46.9054 8.70117 46.3285 8.70117 45.7411V21.4433C8.70117 20.8559 8.85716 20.279 9.15318 19.7717C9.4492 19.2643 9.87465 18.8447 10.386 18.5556L31.9465 6.36929ZM33.5787 8.46718C33.4425 8.46718 33.3086 8.50239 33.1901 8.5694L11.6296 20.7558C11.5078 20.8246 11.4065 20.9245 11.336 21.0453C11.2655 21.1661 11.2284 21.3034 11.2284 21.4433V45.7411C11.2284 45.881 11.2655 46.0183 11.336 46.1391C11.4065 46.2599 11.5078 46.3598 11.6296 46.4286L33.1901 58.615C33.3086 58.682 33.4425 58.7172 33.5787 58.7172C33.7148 58.7172 33.8487 58.682 33.9673 58.615L55.5278 46.4286C55.6495 46.3598 55.7508 46.2599 55.8213 46.1391C55.8918 46.0183 55.9289 45.881 55.9289 45.7411V21.4433C55.9289 21.3034 55.8918 21.1661 55.8213 21.0453C55.7508 20.9245 55.6495 20.8246 55.5278 20.7558L33.9673 8.5694C33.8487 8.50239 33.7148 8.46718 33.5787 8.46718Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.579 25.6156C29.1737 25.6156 25.6024 29.1869 25.6024 33.5922C25.6024 37.9976 29.1737 41.5688 33.579 41.5688C37.9844 41.5688 41.5556 37.9976 41.5556 33.5922C41.5556 29.1869 37.9844 25.6156 33.579 25.6156ZM23.0752 33.5922C23.0752 27.7911 27.7779 23.0884 33.579 23.0884C39.3801 23.0884 44.0829 27.7911 44.0829 33.5922C44.0829 39.3933 39.3801 44.096 33.579 44.096C27.7779 44.096 23.0752 39.3933 23.0752 33.5922Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GearIcon;
