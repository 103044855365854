import React, { useEffect } from "react";
import { Button } from "../Ui";
import EventBox from "./EventBox";
import Http from "../../HttpCommon";
import toast from "react-hot-toast";
import { LeftIcon, RightIcon } from "../Icons";
import { EventListType } from "../../Interface";
import FetchGeolocation from "../../Lib/FetchGeolocation";
import axios from "axios";

const Events = () => {
  const [EventList, setEventList] = React.useState<EventListType | []>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLocationAllow, geolocation] = FetchGeolocation();
  const [pageNo, setPageNo] = React.useState<number>(1);
  const [paginationDetails, setPaginationDetails] = React.useState<any>(null);
  // const [ip, setIp] = React.useState<string>("0.0.0.0");

  // useEffect(() => {}, []);

  const fetchEventList = React.useCallback(() => {
    setIsLoading(true);
    let latLng = [] as any;
    const config = {
      params: {
        page: pageNo,
        limit: 8,
      },
    };
    axios.get("https://api.ipify.org/?format=json").then((res) => {
      const ip = res.data.ip;
      console.log(res.data.ip);

      const body = {
        coordinates: latLng,
        ip,
      };
      if (isLocationAllow) {
        latLng = [geolocation?.longitude, geolocation?.latitude];
        body.coordinates = latLng;
      } else {
        body.coordinates = null;
      }

      Http.post("/event/fetch", body, config)
        .then((response) => {
          setPaginationDetails(response.data.meta);
          setEventList(response.data.data);
        })
        .catch((error) => {
          toast.error(`${error.response.data.errors[0].detail}`);
        })
        .finally(() => {
          setIsLoading(false);
        });
      setIsLoading(false);
    });
  }, [geolocation?.latitude, geolocation?.longitude, isLocationAllow, pageNo]);

  const onRightPage = () => {
    setPageNo(pageNo + 1);
  };

  const onLeftPage = () => {
    setPageNo(pageNo - 1);
  };

  React.useEffect(() => {
    fetchEventList();
  }, [fetchEventList]);

  return (
    <div
      id="event"
      className="container mx-auto px-5 lg:px-20 xl:px-20 2xl:px-32 my-20"
    >
      <div className="flex justify-between items-end my-10">
        <h2 className="font-bold text-2xl md:text-[30px] lg:text-[50px] capitalize">
          Events near me
        </h2>
        <div className="gap-7 flex items-center">
          {pageNo !== 1 && (
            <Button outlined onClick={onLeftPage}>
              <LeftIcon />
            </Button>
          )}
          {paginationDetails?.totalPages !== pageNo && (
            <Button onClick={onRightPage}>
              <RightIcon />
            </Button>
          )}
        </div>
      </div>
      <div
        className={
          EventList.length !== 0
            ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5"
            : "relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 h-60"
        }
      >
        <EventBox
          loading={isLoading}
          EventList={EventList}
          // location={isLocationAllow}
        />
      </div>
    </div>
  );
};

export default Events;
