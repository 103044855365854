import React from "react";

const LinkIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="11"
      viewBox="0 0 19 11"
      fill="currentColor"
      {...props}
    >
      <path
        d="M6 10.5312C6.36244 10.5312 6.65625 10.2374 6.65625 9.875C6.65625 9.51256 6.36244 9.21875 6 9.21875V10.5312ZM13 9.21875C12.6376 9.21875 12.3438 9.51256 12.3438 9.875C12.3438 10.2374 12.6376 10.5312 13 10.5312V9.21875ZM13 0.46875C12.6376 0.46875 12.3438 0.762563 12.3438 1.125C12.3438 1.48744 12.6376 1.78125 13 1.78125V0.46875ZM6 1.78125C6.36244 1.78125 6.65625 1.48744 6.65625 1.125C6.65625 0.762563 6.36244 0.46875 6 0.46875V1.78125ZM6 4.84375C5.63756 4.84375 5.34375 5.13756 5.34375 5.5C5.34375 5.86244 5.63756 6.15625 6 6.15625V4.84375ZM13 6.15625C13.3624 6.15625 13.6562 5.86244 13.6562 5.5C13.6562 5.13756 13.3624 4.84375 13 4.84375V6.15625ZM6 9.21875C3.94619 9.21875 2.28125 7.55381 2.28125 5.5H0.96875C0.96875 8.27868 3.22132 10.5312 6 10.5312V9.21875ZM16.7188 5.5C16.7188 7.55381 15.0538 9.21875 13 9.21875V10.5312C15.7787 10.5312 18.0312 8.27868 18.0312 5.5H16.7188ZM13 1.78125C15.0538 1.78125 16.7188 3.44619 16.7188 5.5H18.0312C18.0312 2.72132 15.7787 0.46875 13 0.46875V1.78125ZM6 0.46875C3.22132 0.46875 0.96875 2.72132 0.96875 5.5H2.28125C2.28125 3.44619 3.94619 1.78125 6 1.78125V0.46875ZM6 6.15625H13V4.84375H6V6.15625Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LinkIcon;
