import React from "react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  value?: string;
  title: string;
  placeholder?: string;
  onChange?: (...args: any[]) => any;
  onKeyPress?: (...args: any[]) => any;
}

const InputText: React.FC<InputProps> = (props) => {
  const {
    className,
    title,
    placeholder,
    children,
    onChange,
    onKeyPress,
    value,
    ...rest
  } = props;

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value);
    }
    return null;
  };

  const handleonKeyPress = (e: any) => {
    if (onKeyPress) {
      onKeyPress(e.key);
    }
    return null;
  };

  return (
    <div className="grid space-y-2 my-4">
      <label htmlFor={title} className="text-blue font-bold text-xs">
        {title}
        <input
          {...rest}
          id={title}
          type={"text"}
          autoCapitalize="off"
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          onKeyPress={handleonKeyPress}
          className="w-full border-b-2 text-black focus:outline-none focus:border-b-2 border-[#C1C0C9] focus:border-blue p-2 font-semibold"
        />
      </label>
    </div>
  );
};

export default InputText;
