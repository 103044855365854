import React from "react";

const ImageIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="currentColor"
      {...props}
    >
      <path
        d="M2.83333 9.95L5.16667 7.61667L11.5833 14.0333L15.6667 9.95L19.1667 13.45V2.83333H2.83333V9.95ZM2.83333 13.2505V19.1667H6.45L9.93367 15.6842L5.16667 10.9172L2.83333 13.2505ZM9.7505 19.1667H19.1667V16.7505L15.6667 13.2505L9.7505 19.1667ZM1.66667 0.5H20.3333C20.6428 0.5 20.9395 0.622916 21.1583 0.841709C21.3771 1.0605 21.5 1.35725 21.5 1.66667V20.3333C21.5 20.6428 21.3771 20.9395 21.1583 21.1583C20.9395 21.3771 20.6428 21.5 20.3333 21.5H1.66667C1.35725 21.5 1.0605 21.3771 0.841709 21.1583C0.622916 20.9395 0.5 20.6428 0.5 20.3333V1.66667C0.5 1.35725 0.622916 1.0605 0.841709 0.841709C1.0605 0.622916 1.35725 0.5 1.66667 0.5ZM15.0833 8.66667C14.6192 8.66667 14.1741 8.48229 13.8459 8.1541C13.5177 7.82591 13.3333 7.3808 13.3333 6.91667C13.3333 6.45254 13.5177 6.00742 13.8459 5.67923C14.1741 5.35104 14.6192 5.16667 15.0833 5.16667C15.5475 5.16667 15.9926 5.35104 16.3208 5.67923C16.649 6.00742 16.8333 6.45254 16.8333 6.91667C16.8333 7.3808 16.649 7.82591 16.3208 8.1541C15.9926 8.48229 15.5475 8.66667 15.0833 8.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ImageIcon;
