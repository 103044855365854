import React from "react";
import { useNavigate } from "react-router-dom";
import { Navbar } from "../Common";
import { RightArrow } from "../Icons";
import { Button } from "../Ui";

const Banner = () => {
  let navigate = useNavigate();
  return (
    <div className="h-fit bg-blue" id="home">
      <div className="bg-banner_ring bg-no-repeat bg-right-top h-full">
        <div className="bg-left_bg bg-no-repeat bg-contain bg-left-bottom h-full">
          <div className="bg-banner_bg bg-no-repeat bg-contain bg-right-bottom h-full relative">
            <Navbar />
            <div className="flex items-center justify-center h-full pb-20 md:py-20">
              <div className="items-center text-center">
                <h2 className="text-xl md:text-3xl font-semibold text-white capitalize max-w-lg mb-10">
                  A great place to create and track important events.
                </h2>
                <Button
                  outlined
                  onClick={() => navigate(`/?view=create_event`)}
                >
                  Create Event <RightArrow className="ml-2" />
                </Button>
              </div>
            </div>
            <div
              className="h-[60px] md:h-[100px] w-full bg-white absolute md:-bottom-12 -bottom-7"
              style={{ borderRadius: "150% 150% 150% 150%" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
