import {
  BrandFullIcon,
  BrandIcon,
  IntroIcon,
  LinkIcon,
  LocationIcon,
  UpdateIcon,
} from "../../Icons";
import React from "react";
import { EnrollListType, EventDetailType } from "../../../Interface";
import Moment from "react-moment";
import toast from "react-hot-toast";
import Http from "../../../HttpCommon";
import { useOutsideClick } from "../../../Lib/Hooks";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { Avatar, Button, InputText, Modal, Textarea } from "../../Ui";
import { HashLink } from "react-router-hash-link";

const buttonValues = [
  {
    title: "coming",
    value: "coming",
  },
  {
    title: "maybe",
    value: "maybe",
  },
  {
    title: "sorry",
    value: "sorry",
  },
];

const navigation = [
  {
    title: "Home",
    path: "#home",
  },
  {
    title: "Events",
    path: "#event",
  },
  {
    title: "About",
    path: "#about",
  },
  // {
  //   title: "Contact Us",
  //   path: "?view=contact",
  // },
];

const Navbar = () => {
  let navigate = useNavigate();
  const [isToggleOpen, setIsToggleOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [contactModal, setContactModal] = React.useState<boolean>(false);
  const [enrollListModal, setEnrollListModal] = React.useState<boolean>(false);
  const [eventView, setEventView] = React.useState<boolean>(false);
  const [eventDetails, setEventDetails] =
    React.useState<EventDetailType | null>(null);
  const [enrollCount, setEnrollCount] = React.useState<any[]>([]);
  const [enrollList, setEnrollList] = React.useState<EnrollListType | []>([]);
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [phone, setPhone] = React.useState<number>(0);
  const [message, setMessage] = React.useState<string>("");
  const [eventEnroll, setEventEnroll] = React.useState("");
  const [searchParams, setSearchParams] = useSearchParams({
    view: "",
    rsvp: "",
  }) as any;
  const activeView = searchParams.get("view");
  const activeRsvp = searchParams.get("rsvp");
  const ref = React.useRef<any>();

  const paramsSearch = React.useCallback(() => {
    switch (activeView) {
      case "contact":
        setContactModal(true);
        break;
    }
  }, [activeView]);

  const getRsvp = React.useCallback(() => {
    //CHECK FOR RSVP
    if (activeRsvp !== "" || null || undefined) {
      //API CALL
      Http.get(`/event/fetch/${activeRsvp}`)
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            setEventDetails(response.data.data.event);
            const enrollCount = [
              response.data.data.comingCount,
              response.data.data.maybeCount,
              response.data.data.sorryCount,
            ];
            setEnrollCount(enrollCount);
            setEventView(true);
          }
        })
        .catch((error) => {
          toast.error(`${error.response.data.errors[0].detail}`);
        });
    }
  }, [activeRsvp]);

  const eventViewModelCloseHandle = () => {
    setSearchParams({});
    setEventView(false);
  };

  const onContactModelCloseHandle = () => {
    setContactModal(false);
    setSearchParams({});
  };

  useOutsideClick(ref, () => {
    if (isToggleOpen) {
      setIsToggleOpen(false);
    }
  });

  const onContact = () => {
    setIsLoading(true);
    //API BODY
    const body = {
      name: name,
      email: email,
      phone: phone,
      message: message,
    };
    //API CALL
    Http.post("/contact/create", body)
      .then((response) => {
        if (response.status === 201) {
          toast.success("Thanks, we will contact you shortly.");
          onContactModelCloseHandle();
        }
      })
      .catch((error) => {
        toast.error(`${error.response.data.errors[0].detail}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onViewEnrollUser = (list: string, id: any) => {
    setEventEnroll(list);
    setIsLoading(true);
    //API CONFIG
    const config = {
      params: {
        status: list,
      },
    };
    //API CALL
    Http.get(`/eventEnroll/fetch/${id}`, config)
      .then((response) => {
        if (response.status === 201) {
          setEnrollList(response.data.data.event);
          setEnrollListModal(true);
        }
      })
      .catch((error) => {
        toast.error(`${error.response.data.errors[0].detail}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onNavigateUpdateEvent = (id: any) => {
    eventViewModelCloseHandle();
    setTimeout(() => {
      setSearchParams({ update: id });
    }, 100);
  };

  const onVisitLink = (link: any) => {
    window.open(link);
  };

  React.useEffect(() => {
    paramsSearch();
    getRsvp();
  }, [paramsSearch, getRsvp]);

  return (
    <>
      <div
        ref={ref}
        className="relative flex justify-between items-center w-full px-5 py-5"
      >
        {isToggleOpen && (
          <div className="absolute top-0 left-0 h-screen w-52 bg-blue shadow-xl text-white z-50">
            <div className="px-7 py-10 space-y-7">
              <div className="text-white">
                <BrandFullIcon height={150} width={150} />
              </div>
              <ul className="grid space-y-5 text-white">
                {navigation.map((item, index) => {
                  return (
                    <li key={index} className="">
                      <HashLink key={index} smooth to={`#${item?.path}`}>
                        {item?.title}
                      </HashLink>
                      {/* <NavLink to={item.path} className="">
                        {item.title}
                      </NavLink> */}
                    </li>
                  );
                })}
                <Button outlined onClick={() => navigate(`/?view=contact`)}>
                  Contact Us
                </Button>
              </ul>
            </div>
          </div>
        )}
        <div className="text-white hidden md:block">
          <NavLink to="#home" className="">
            <IntroIcon height={60} width={170} />
          </NavLink>
        </div>
        <div
          className="text-white block md:hidden"
          onClick={() => setIsToggleOpen(true)}
        >
          <BrandIcon height={50} width={50} />
        </div>
        <ul className="hidden md:flex items-center gap-14 text-white">
          {navigation.map((item, index) => {
            return (
              <li key={index} className="">
                <HashLink key={index} smooth to={`${item?.path}`}>
                  {item?.title}
                </HashLink>
              </li>
            );
          })}
          <Button outlined onClick={() => navigate(`/?view=contact`)}>
            Contact Us
          </Button>
        </ul>
      </div>

      {/* CONATCT US */}
      <Modal
        // title="Contact Us"
        maxWidth="lg"
        show={contactModal}
        onClose={onContactModelCloseHandle}
        showCancel={true}
      >
        <div className="space-y-10 py-7 px-8">
          <div>
            <h2 className="font-medium text-[40px] capitalize">Contact Us</h2>
            <p>
              We love hearing from our users and we appreciate your comments and
              feedback.
            </p>
          </div>
          <InputText
            title={"Your name"}
            placeholder={"Your name"}
            onChange={(e) => setName(e)}
          />
          <InputText
            title={"Your email"}
            placeholder={"Your email"}
            onChange={(e) => setEmail(e)}
          />
          <InputText
            title={"Your phone #"}
            placeholder={"Your phone"}
            onChange={(e) => setPhone(e)}
          />
          <Textarea
            title={"Message"}
            placeholder={"Enter your message..."}
            onChange={(e) => setMessage(e)}
          />
          <Button width="full" loading={isLoading} onClick={onContact}>
            Send
          </Button>
        </div>
      </Modal>

      {/* EVENT VIEW BY USER */}
      <Modal
        // title="Event View"
        maxWidth="xl"
        show={eventView}
        onClose={eventViewModelCloseHandle}
        showCancel={true}
      >
        <div className="h-36 w-auto">
                    <img
                    style={{ objectFit: 'contain' }}
                      className="h-full w-full object-cover rounded-tr-lg rounded-tl-lg"
                      src={eventDetails?.imageUrl || `/img/event/404.png`}
                      alt={eventDetails?.title}
                    />
                  </div>
        <div className="space-y-5 py-7 px-8">
          <div className="text-sm text-black text-center space-y-1">
            <h2 className="text-2xl font-bold text-blue">
              {eventDetails?.title}
            </h2>
            <p className="text-blue font-semibold">
              <Moment date={eventDetails?.time} format="h:mm A" />
            </p>
            <p>
              <span className="font-semibold">
                <Moment fromNow>{eventDetails?.time}</Moment>
              </span>{" "}
              | <Moment format="MMM D yyyy">{eventDetails?.time}</Moment>
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            {eventDetails?.eventLink !== "" && (
              <div className="space-y-2 text-blue">
                <h3 className="font-bold text-sm">Event Link</h3>
                <div className="relative flex text-sm">
                  <LinkIcon className="text-gray h-5 w-5 absolute" />
                  <p
                    className="ml-7 hover:underline cursor-pointer"
                    onClick={() => onVisitLink(eventDetails?.eventLink)}
                  >
                    {eventDetails?.eventLink}
                  </p>
                </div>
              </div>
            )}
            {eventDetails?.eventAddress !== "" && (
              <div className="space-y-2 text-blue w-full">
                <h3 className="font-bold text-sm">Location</h3>
                <div className="relative flex text-sm">
                  <LocationIcon className="text-gray h-5 w-5 absolute" />
                  <p className="ml-7 text-gray">{eventDetails?.eventAddress}</p>
                </div>
              </div>
            )}
          </div>
          {eventDetails?.description !== "" && (
            <div className="space-y-2 text-blue w-full">
              <h3 className="font-bold text-sm">Description</h3>
              <div className="text-sm">
                <p className="text-gray">{eventDetails?.description}</p>
              </div>
            </div>
          )}
          {eventDetails?.eventLink !== "" && (
            <div className="space-y-2 text-blue">
              <h3 className="font-bold text-sm">Event Link</h3>
              <div className="relative flex text-sm">
                <LinkIcon className="text-gray h-5 w-5 absolute" />
                <p
                  className="ml-7 hover:underline cursor-pointer"
                  onClick={() => onVisitLink(eventDetails?.eventLink)}
                >
                  {eventDetails?.eventLink}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="border-t-2 border-gray/50 py-7 px-8">
          <div className="text-blue mb-10 w-full lg:w-3/4 mx-auto">
            <h3 className="font-bold text-sm">RSVP Received</h3>
            <p className="text-xs text-gray">Click below for more details.</p>
            <ul className="flex justify-between items-center pt-5">
              {buttonValues.map((list, index) => {
                return (
                  <li className="w-fit relative" key={index}>
                    <input
                      type="radio"
                      name="answer"
                      id={list.value}
                      value={list.value}
                      className="sr-only peer"
                      onChange={() =>
                        onViewEnrollUser(list.value, eventDetails?._id)
                      }
                    />
                    <label
                      className="px-4 py-2 bg-white capitalize text-sm select-none font-bold border-2 border-gray rounded cursor-pointer focus:outline-none peer-checked:ring-blue peer-checked:border-transparent peer-checked:bg-blue peer-checked:text-white"
                      htmlFor={list.value}
                    >
                      {list.title}
                    </label>
                    <div className="absolute -top-5 -right-3 h-7 w-7 rounded-full bg-white shadow-md border border-gray flex justify-center items-center">
                      <p className="text-xs font-semibold peer-checked:text-blue">
                        {enrollCount[index]}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <Button
            width="full"
            onClick={() => onNavigateUpdateEvent(eventDetails?._id)}
          >
            Edit Event <UpdateIcon className="ml-2" />
          </Button>
        </div>
      </Modal>

      {/* EVENT ENROLL USERS LIST */}
      <Modal
        maxWidth="lg"
        showCancel={true}
        show={enrollListModal}
        onClose={() => setEnrollListModal(false)}
        title={`${eventEnroll} (${enrollList.length})`}
      >
        <div className="space-y-5 py-7 px-8">
          {enrollList.map((list, index) => {
            return (
              <div
                key={index}
                className="flex gap-4 items-center border-b border-gray pb-5"
              >
                <Avatar
                  image={""}
                  userName={list.userId.name}
                  isGroup={false}
                />
                <div>
                  <h2 className="font-semibold text-lg">{list.userId.name}</h2>
                  <p className="text-sm">{list.userId.email}</p>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};

export default Navbar;
