import React from "react";

const LocationIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="currentColor"
      {...props}
    >
      <path
        d="M8.17075 13.3605L7.70668 12.9965L8.17075 13.3605ZM4.61751 13.3605L4.15345 13.7244L4.61751 13.3605ZM6.39413 14.8331V14.2434V14.8331ZM10.9155 6.91293C10.9155 7.66599 10.5198 8.69308 9.87806 9.82762C9.24938 10.9391 8.43565 12.067 7.70668 12.9965L8.63482 13.7244C9.38152 12.7722 10.235 11.5922 10.9047 10.4083C11.5613 9.2475 12.095 7.99171 12.095 6.91293H10.9155ZM5.08158 12.9965C4.35261 12.067 3.53888 10.9391 2.91021 9.82762C2.26847 8.69308 1.87276 7.66599 1.87276 6.91293H0.693276C0.693276 7.99171 1.22697 9.2475 1.88357 10.4083C2.55322 11.5922 3.40674 12.7722 4.15345 13.7244L5.08158 12.9965ZM1.87276 6.91293C1.87276 4.1596 3.93703 2.00268 6.39413 2.00268V0.82319C3.20565 0.82319 0.693276 3.59113 0.693276 6.91293H1.87276ZM6.39413 2.00268C8.85123 2.00268 10.9155 4.1596 10.9155 6.91293H12.095C12.095 3.59113 9.58261 0.82319 6.39413 0.82319V2.00268ZM7.70668 12.9965C7.30881 13.5039 7.05498 13.8244 6.83037 14.0284C6.63101 14.2095 6.51439 14.2434 6.39413 14.2434V15.4228C6.89566 15.4228 7.28241 15.2113 7.62344 14.9015C7.93922 14.6146 8.2628 14.1988 8.63482 13.7244L7.70668 12.9965ZM4.15345 13.7244C4.52546 14.1988 4.84905 14.6146 5.16483 14.9015C5.50586 15.2113 5.8926 15.4228 6.39413 15.4228V14.2434C6.27387 14.2434 6.15725 14.2095 5.95789 14.0284C5.73328 13.8244 5.47945 13.5039 5.08158 12.9965L4.15345 13.7244ZM3.88772 7.16293C3.88772 8.54719 5.00988 9.66934 6.39413 9.66934V8.48986C5.66129 8.48986 5.06721 7.89577 5.06721 7.16293H3.88772ZM6.39413 9.66934C7.77838 9.66934 8.90054 8.54719 8.90054 7.16293H7.72106C7.72106 7.89577 7.12697 8.48986 6.39413 8.48986V9.66934ZM8.90054 7.16293C8.90054 5.77868 7.77838 4.65652 6.39413 4.65652V5.83601C7.12697 5.83601 7.72106 6.43009 7.72106 7.16293H8.90054ZM6.39413 4.65652C5.00988 4.65652 3.88772 5.77868 3.88772 7.16293H5.06721C5.06721 6.43009 5.66129 5.83601 6.39413 5.83601V4.65652Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LocationIcon;
