import React from "react";

const LaptopIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      {...props}
    >
      <path
        d="M0.615224 6.24939C0.615224 8.83045 0.964557 10.1123 1.82763 10.8316C2.26825 11.1987 2.89914 11.4681 3.83426 11.6413C4.77095 11.8148 5.96707 11.8838 7.49963 11.8838C9.03219 11.8838 10.2283 11.8148 11.165 11.6413C12.1001 11.4681 12.731 11.1987 13.1716 10.8316C14.0347 10.1123 14.384 8.83045 14.384 6.24939C14.384 3.66833 14.0347 2.38645 13.1716 1.66722C12.731 1.30004 12.1001 1.03065 11.165 0.857469C10.2283 0.683992 9.03219 0.614979 7.49963 0.614979C5.96707 0.614979 4.77095 0.683992 3.83426 0.857469C2.89914 1.03065 2.26825 1.30004 1.82763 1.66722C0.964557 2.38645 0.615224 3.66833 0.615224 6.24939Z"
        stroke="#2D2D2D"
        strokeWidth="1.23118"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.87479 14.3745H13.1248"
        stroke="#2D2D2D"
        strokeWidth="1.23118"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LaptopIcon;
