import React from "react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  title: string;
  value?: any;
  placeholder?: string;
  onChange?: (...args: any[]) => any;
}

const InputRadio: React.FC<InputProps> = (props) => {
  const { className, title, placeholder, children, onChange, value, ...rest } =
    props;

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.checked);
    }
    return null;
  };

  return (
    <div className="grid space-y-2 my-4">
      <label htmlFor={title} className="text-blue flex gap-3">
        <input
          {...rest}
          id={title}
          type={"checkbox"}
          checked={value}
          autoCapitalize="off"
          placeholder={placeholder}
          onChange={handleOnChange}
          className="text-blue"
        />
        <p>{title}</p>
      </label>
    </div>
  );
};

export default InputRadio;
