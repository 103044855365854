import {
  Button,
  ImageUpload,
  InputDate,
  InputRadio,
  InputText,
  Modal,
  Textarea,
} from "../Ui";
import {
  EventDetailType,
  ImageUploadType,
  LocationType,
} from "../../Interface";
import React, { useEffect } from "react";
import moment from "moment";
import Moment from "react-moment";
import Http from "../../HttpCommon";
import toast from "react-hot-toast";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { useSearchParams } from "react-router-dom";
import FetchGeolocation from "../../Lib/FetchGeolocation";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { RightArrow, XIcon } from "../Icons";
import axios from "axios";

const EventCreate = () => {
  const [createEvent, setCreateEvent] = React.useState<boolean>(false);
  const [updateEvent, setUpdateEvent] = React.useState<boolean>(false);
  const [UpdateEventDetails, setUpdateEventDetails] = React.useState<
    any | null
  >(null);
  const [eventCreateSucess, setEventCreateSucess] =
    React.useState<boolean>(false);
  const [addLocation, setAddLocation] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [addMoreDetails, setAddMoreDetails] = React.useState<boolean>(true);
  const [isCopied, setIsCopied] = React.useState(false);
  const [address, setAddress] = React.useState<LocationType | null>(null);
  const [successEventCreateDetail, setSuccessEventCreateDetail] =
    React.useState<EventDetailType | null>(null);
  const [eventTitle, setEventTitle] = React.useState("");
  const [eventTime, setEventTime] = React.useState("");
  const [eventLink, setEventLink] = React.useState("");
  const [eventAddress, setEventAddress] = React.useState("");
  const [eventImage, setEventImage] = React.useState<ImageUploadType | null>(
    null
  );
  const [eventDesc, setEventDesc] = React.useState("");
  const [eventMoreDetails, setEventMoreDetails] = React.useState("");
  const [eventLatLng, setEventLatLng] = React.useState<any>([]);
  const [eventPublic, setEventPublic] = React.useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams({
    view: "",
    rsvp: "",
    update: "",
  });
  const activeView = searchParams.get("view");
  const activeUpdate = searchParams.get("update");
  const [isLocationAllow, geolocation] = FetchGeolocation();
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [isAllow, setIsAllow] = React.useState<boolean>(false);
  const [invitaionText, setInvitaionText] = React.useState<string>("");
  //   const [ip, setIp] = React.useState<string>("0.0.0.0");

  //   useEffect(()=> {
  //     axios.get('https://api.ipify.org/?format=json').then(res=> {
  // setIp(res.data.ip)
  // console.log(res.data.ip);

  //     })
  //   },[])
  const paramsSearch = React.useCallback(() => {
    switch (activeView) {
      case "create_event":
        setCreateEvent(true);
        break;
    }
  }, [activeView]);

  const getUpdateEvent = React.useCallback(() => {
    //CHECK FOR RSVP
    if (activeUpdate !== "" || null || undefined) {
      //API CALL
      Http.get(`/event/fetch/${activeUpdate}`)
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.data.data.event);
            setUpdateEvent(true);
            setUpdateEventDetails(response.data.data.event);
            // const enrollCount = [
            //   response.data.data.comingCount,
            //   response.data.data.maybeCount,
            //   response.data.data.sorryCount,
            // ];
            // setEnrollCount(enrollCount);
            // setEventView(true);
          }
        })
        .catch((error) => {
          toast.error(`${error.response.data.errors[0].detail}`);
        });
    }
  }, [activeUpdate]);

  const onAllowModelCloseHandle = () => {
    setIsAllow(false);
  };

  const onAllowEventUpdate = () => {
    setIsLoading(true);
    const body = {
      name: name,
      email: email,
      eventId: successEventCreateDetail?._id,
    };

    //API CALL
    Http.post(`/event/allow`, body)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          toast.success("Event update link send successfully.");
          setIsAllow(false);
          setTimeout(() => {
            setEventCreateSucess(false);
            setSearchParams({});
          }, 1000);
        }
      })
      .catch((error) => {
        toast.error(`${error.response.data.errors[0].detail}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const UpdateEventModelCloseHandle = () => {
    setSearchParams({});
    setUpdateEvent(false);
  };

  const createEventModelOpenHandle = () => {
    setSearchParams({ view: "create_event" });
  };

  const createEventModelCloseHandle = () => {
    setSearchParams({});
    setCreateEvent(false);
  };

  const generateLocation = async (add: LocationType) => {
    setAddress(add);
    const geocodeObj =
      add && add.value && (await geocodeByPlaceId(add.value.place_id));
    let location = geocodeObj[0]?.formatted_address;
    console.log(geocodeObj);

    setEventAddress(location);
    geocodeByAddress(location)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        let latLng = [lng, lat];
        setEventLatLng(latLng);
        // console.log("Successfully got latitude and longitude", { lat, lng });
      });
  };

  const generateLocationUpdate = async (add: LocationType) => {
    setAddress(add);
    const geocodeObj =
      add && add.value && (await geocodeByPlaceId(add.value.place_id));
    let location = geocodeObj[0]?.formatted_address;
    console.log(geocodeObj);
    geocodeByAddress(location)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        let updateCoord = {
          type: "Point",
          coordinates: [lng, lat],
        };
        setUpdateEventDetails({
          ...UpdateEventDetails,
          location: updateCoord,
          eventAddress: location,
        });
        // console.log("Successfully got latitude and longitude", { lat, lng });
      });
  };

  const onCreateEvent = () => {
    if (eventPublic && (!eventAddress || eventAddress === "")) {
      toast.error("Please enter address for public event");
      return;
    }
    setIsLoading(true);
    let latLng = [] as any[];
    if (eventLatLng.length === 0) {
      latLng = [geolocation?.longitude, geolocation?.latitude];
    }
    const auth = JSON.parse(localStorage.getItem("sveeva") as any);

    const config = {
      headers: {
        Authorization: `Bearer ${auth?.accessToken}`,
      },
    };

    axios.get("https://api.ipify.org/?format=json").then((res) => {
      const ip = res.data.ip;

      //API BODY

      const body = {
        title: eventTitle,
        time: new Date(eventTime),
        eventLink: eventLink,
        eventAddress: eventAddress,
        imageUrl: eventImage?.url || "",
        imageUrlId: eventImage?.public_id || "",
        description: eventDesc,
        moreDetails: eventMoreDetails,
        location:
          isLocationAllow === false
            ? null
            : {
                type: "Point",
                coordinates: eventLatLng.length === 0 ? latLng : eventLatLng,
              },
        isPublic: eventPublic,
        ip,
      };
      console.log(body, config);

      //API CALL
      Http.post(`/event/create`, body, config)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            setSuccessEventCreateDetail(response.data.data);
            setInvitaionText(
              `You are invited to the Event ${
                response.data.data?.title
              }. at ${moment(response.data.data?.time).format(
                "h:mm A"
              )}, on ${moment(response.data.data?.time).format(
                "D MMM yyyy"
              )}, to rsvp or get a reminder from sveeva.com`
            );
            toast.success("Event create successfully.");
            setSearchParams({});
            setCreateEvent(false);
            setTimeout(() => {
              setEventCreateSucess(true);
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(`${error.response.data.errors[0].detail}`);
          if (error.response.data.errors[0].code === 401) {
            setSearchParams({ view: "login" });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const onUpdateEvent = () => {
    setIsLoading(true);
    console.log(UpdateEventDetails);
    debugger;
    const data = {
      ...UpdateEventDetails,
      time: new Date(UpdateEventDetails.time),
    };
    //API CALL
    Http.patch(`/event/update/${UpdateEventDetails?._id}`, data)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Event updated successfully.");
          UpdateEventModelCloseHandle();
        }
      })
      .catch((error) => {
        toast.error(`${error.response.data.errors[0].detail}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  React.useEffect(() => {
    paramsSearch();
    getUpdateEvent();
  }, [paramsSearch, getUpdateEvent]);

  console.log(UpdateEventDetails);

  return (
    <>
      <div className="bg-blue text-white px-5 lg:px-20 xl:px-20 2xl:px-32 py-20 relative my-20">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
            <div>
              <h2 className="font-bold text-[35px] lg:text-[55px] max-w-xs lg:max-w-lg mb-3 leading-[1]">
                Create your own event
              </h2>
              <p className="max-w-sm mb-4 text-white/60">
                Create your own event and share it with your friends.
              </p>
              <Button outlined onClick={() => createEventModelOpenHandle()}>
                Create Event <RightArrow className="ml-2" />
              </Button>
            </div>
            <img
              className="lg:absolute h-full w-auto right-0 top-0 opacity-30"
              src="../img/event/create_sample.png"
              alt=""
            />
          </div>
        </div>
      </div>

      {/* CREATE EVENT */}
      <Modal
        maxWidth="2xl"
        showCancel={true}
        show={createEvent}
        title="Create Event"
        onClose={createEventModelCloseHandle}
      >
        <div className="space-y-2 py-7 px-8">
          <div className="grid grid-cols-1">
            <InputText
              title={"Event Title"}
              placeholder={"Event Name"}
              onChange={(e) => setEventTitle(e)}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5">
            <InputDate
              title={"Date & Time"}
              placeholder={"Event Name"}
              onChange={(e) => setEventTime(e)}
            />
            <InputText
              title={"Event Link"}
              placeholder={"Event Link"}
              onChange={(e) => setEventLink(e)}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5">
            <ImageUpload
              title={"Upload Image"}
              onChange={(e) => setEventImage(e)}
            />
            <Textarea
              title={"Description"}
              placeholder={"Event Description"}
              onChange={(e) => setEventDesc(e)}
            />
          </div>
          <div className="grid grid-cols-1">
            {addLocation === false && (
              <div className="flex gap-3 items-center">
                <div className="w-full">
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyDkobc92Qm9QLZ2HzCxjY2Seg-a1jT3_OY"
                    selectProps={{
                      isClearable: true,
                      value: address,
                      onChange: (val: LocationType) => {
                        generateLocation(val);
                      },
                    }}
                  />
                </div>
                <XIcon
                  className="h-3 w-3 cursor-pointer text-black"
                  onClick={() => setAddLocation(true)}
                />
              </div>
            )}
            {addMoreDetails === false && (
              <div className="flex gap-3 items-center">
                <div className="w-full">
                  <InputText
                    title={"Add more link"}
                    placeholder={"Link to more event information "}
                    onChange={(e) => setEventMoreDetails(e)}
                  />
                </div>
                <XIcon
                  className="h-3 w-3 cursor-pointer text-black"
                  onClick={() => setAddMoreDetails(true)}
                />
              </div>
            )}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5">
            {addLocation && (
              <Button outlined onClick={() => setAddLocation(false)}>
                + Add Location
              </Button>
            )}
            {addMoreDetails && (
              <Button outlined onClick={() => setAddMoreDetails(false)}>
                + Add More Details
              </Button>
            )}
          </div>
          <div className="grid grid-cols-1">
            <InputRadio
              title={"Make this Event Public"}
              onChange={(e) => {
                setEventPublic(e);
                if (e) {
                  setAddLocation(false);
                } else {
                  setAddLocation(true);
                }
              }}
            />
          </div>
          <div className="flex items-center justify-center space-x-3">
            <Button width="full" onClick={onCreateEvent} loading={isLoading}>
              Create
            </Button>
          </div>
        </div>
      </Modal>

      {/* EVENT SUCCESS */}
      <Modal
        // title="Event Created"
        maxWidth="lg"
        show={eventCreateSucess}
        onClose={() => setEventCreateSucess(false)}
        showCancel={true}
      >
        <div className="space-y-5 py-7">
          <div className="mb-10">
            <img
              style={{ objectFit: "contain" }}
              className="mx-auto h-52 w-52"
              src="/img/event/success.gif"
              alt=""
            />
            <h1 className="text-center text-2xl font-medium">Event Created!</h1>
            <CopyToClipboard
              text={`http://www.sveeva.com/?view=rsvp=${successEventCreateDetail?._id}`}
            >
              <p
                onClick={() => toast.success("Event link copied.")}
                className="text-blue text-sm text-center"
              >
                Copy Event Link
              </p>
            </CopyToClipboard>
          </div>
          <div className="space-y-4 justify-between items-center border-gray border-t-2 border-b-2 py-5 px-8">
            <div className="w-fit mx-auto">
              <div className="flex gap-5 items-center">
                <img
                  className="h-20 w-20 rounded object-cover"
                  src={
                    successEventCreateDetail?.imageUrl || `/img/event/404.png`
                  }
                  alt={successEventCreateDetail?.title}
                />
                <div className="text-lg text-[#656565]">
                  <p>
                    Event:{" "}
                    <span className="text-blue font-semibold">
                      {successEventCreateDetail?.title}
                    </span>
                  </p>
                  <p>
                    At:{" "}
                    <Moment format="MMM D yyyy">
                      {successEventCreateDetail?.time}
                    </Moment>{" "}
                    at{" "}
                    <Moment
                      date={successEventCreateDetail?.time}
                      format="h:mm A"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-sm text-[#656565] px-8">
            <Textarea
              title={"Invitation Text"}
              placeholder={`Invitation Text`}
              value={invitaionText}
              onChange={(e) => setInvitaionText(e)}
            />
            <hr className="mt-2 text-gray" />
          </div>
          <div className="flex items-center justify-center text-xs space-x-3 mt-10 px-8">
            <CopyToClipboard
              text={`${invitaionText} http://www.sveeva.com/?view=rsvp=${successEventCreateDetail?._id}`}
            >
              <Button width="full" outlined onClick={onCopyText}>
                {isCopied ? `Copied` : `Copy Invite Text`}
              </Button>
            </CopyToClipboard>
            <Button width="full" onClick={() => setIsAllow(true)}>
              Allow Edit & RSVP <RightArrow className="ml-2" />
            </Button>
          </div>
        </div>
      </Modal>

      {/* USER ALLOW EDIT EVENT */}
      <Modal
        // title="Login User"
        maxWidth="lg"
        show={isAllow}
        onClose={onAllowModelCloseHandle}
        showCancel={true}
      >
        <div className="space-y-10 py-7 px-8">
          <div>
            <h2 className="font-medium text-[40px] capitalize">
              Allow Edit & RSVP
            </h2>
            <p>Get a link to view RSVP List and make changes to your event.</p>
          </div>
          <InputText
            title={"Your name"}
            placeholder={"Your name"}
            onChange={(e) => setName(e)}
          />
          <InputText
            title={"Your email"}
            placeholder={"Your email"}
            onChange={(e) => setEmail(e)}
          />
          <Button width="full" loading={isLoading} onClick={onAllowEventUpdate}>
            Submit
          </Button>
        </div>
      </Modal>

      {/* UPDATE EVENT */}
      <Modal
        maxWidth="lg"
        showCancel={true}
        show={updateEvent}
        title="Update Event"
        onClose={UpdateEventModelCloseHandle}
      >
        <div className="space-y-2 py-7 px-8">
          <div className="grid grid-cols-1">
            <InputText
              title={"Event Title"}
              placeholder={"Event Name"}
              value={UpdateEventDetails?.title}
              onChange={(e) => {
                setUpdateEventDetails({
                  ...UpdateEventDetails,
                  title: e,
                });
              }}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5">
            <InputDate
              title={"Date & Time"}
              placeholder={"Event time"}
              defaultValue={moment(UpdateEventDetails?.time).format(
                "YYYY-MM-DDTHH:mm"
              )}
              onChange={(e) => {
                setUpdateEventDetails({
                  ...UpdateEventDetails,
                  time: e,
                });
              }}
            />
            <InputText
              title={"Event Link"}
              placeholder={"Event Link"}
              value={UpdateEventDetails?.eventLink}
              onChange={(e) => {
                setUpdateEventDetails({
                  ...UpdateEventDetails,
                  eventLink: e,
                });
              }}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5">
            <ImageUpload
              title={"Upload Image"}
              value={UpdateEventDetails?.imageUrl}
              onChange={(e) => {
                setUpdateEventDetails({
                  ...UpdateEventDetails,
                  imageUrl: e.url,
                  imageUrlId: e.public_id,
                });
              }}
            />
            <Textarea
              title={"Description"}
              placeholder={"Event Description"}
              value={UpdateEventDetails?.description}
              onChange={(e) => {
                setUpdateEventDetails({
                  ...UpdateEventDetails,
                  description: e,
                });
              }}
            />
          </div>
          <div className="grid grid-cols-1">
            {addLocation === false && (
              <div className="flex gap-3 items-center">
                <div className="w-full">
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyDkobc92Qm9QLZ2HzCxjY2Seg-a1jT3_OY"
                    selectProps={{
                      isClearable: true,
                      value: address,
                      onChange: (val: LocationType) => {
                        generateLocationUpdate(val);
                      },
                    }}
                  />
                </div>
                <XIcon
                  className="h-3 w-3 cursor-pointer text-black"
                  onClick={() => setAddLocation(true)}
                />
              </div>
            )}
            {addMoreDetails === false && (
              <div className="flex gap-3 items-center">
                <div className="w-full">
                  <InputText
                    title={"Add more link"}
                    placeholder={"Link to more event information "}
                    value={UpdateEventDetails?.moreDetails}
                    onChange={(e) => {
                      setUpdateEventDetails({
                        ...UpdateEventDetails,
                        moreDetails: e,
                      });
                    }}
                  />
                </div>
                <XIcon
                  className="h-3 w-3 cursor-pointer text-black"
                  onClick={() => setAddMoreDetails(true)}
                />
              </div>
            )}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5">
            {addLocation && (
              <Button outlined onClick={() => setAddLocation(false)}>
                + Add Location
              </Button>
            )}
            {addMoreDetails && (
              <Button outlined onClick={() => setAddMoreDetails(false)}>
                + Add More Details
              </Button>
            )}
          </div>
          <div className="grid grid-cols-1">
            <InputRadio
              title={"Make this Event Public"}
              value={UpdateEventDetails?.isPublic}
              onChange={(e) => {
                setUpdateEventDetails({
                  ...UpdateEventDetails,
                  isPublic: e,
                });
                if (UpdateEventDetails?.isPublic) {
                  setAddLocation(false);
                } else {
                  setAddLocation(true);
                }
              }}
            />
          </div>
          <div className="flex items-center justify-center space-x-3">
            <Button width="full" onClick={onUpdateEvent} loading={isLoading}>
              Update Event
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EventCreate;
