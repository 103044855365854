// @ts-ignore
import { RWebShare } from "react-web-share";

// const style = {
//   background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
//   borderRadius: 3,
//   border: 0,
//   color: "white",
//   padding: "0 30px",
//   boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
// };
export const ReactSocialShare: React.FC<{}> = () => {
  return (
    <RWebShare
      data={{
        text: "Like humans, flamingos make friends for life",
        url: "https://on.natgeo.com/2zHaNup",
        title: "Flamingos",
      }}
      onClick={() => console.log("shared successfully!")}
    >
      <button>Share 🔗</button>
    </RWebShare>
    // <ShareSocial
    //   style={style}
    //   url="sveeva.com"
    //   socialTypes={["facebook", "twitter", "reddit", "linkedin"]}
    // />
  );
};
