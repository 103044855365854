import React from "react";

const PersonIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="67"
      viewBox="0 0 66 67"
      fill="currentColor"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.6799 15.9581C16.0065 15.9581 10.5966 21.368 10.5966 28.0415C10.5966 34.7149 16.0065 40.1248 22.6799 40.1248C29.3534 40.1248 34.7633 34.7149 34.7633 28.0415C34.7633 21.368 29.3534 15.9581 22.6799 15.9581ZM8.06934 28.0415C8.06934 19.9723 14.6107 13.4309 22.6799 13.4309C30.7491 13.4309 37.2905 19.9723 37.2905 28.0415C37.2905 36.1107 30.7491 42.6521 22.6799 42.6521C14.6107 42.6521 8.06934 36.1107 8.06934 28.0415Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.4371 16.095C43.7304 15.8329 41.9874 15.9396 40.3254 16.4078C39.6536 16.5971 38.9557 16.206 38.7664 15.5343C38.5772 14.8625 38.9683 14.1646 39.64 13.9753C41.6496 13.4091 43.7571 13.2801 45.8207 13.5971C47.8843 13.914 49.856 14.6694 51.6031 15.8125C53.3501 16.9556 54.832 18.4599 55.9487 20.2239C57.0655 21.9879 57.7912 23.9707 58.0771 26.0388C58.3631 28.107 58.2024 30.2124 57.6062 32.2132C57.0099 34.214 55.9917 36.0638 54.6203 37.638C53.2489 39.2123 51.5561 40.4743 49.6559 41.3392C47.7557 42.2041 45.6922 42.6517 43.6044 42.652C42.9065 42.6521 42.3407 42.0864 42.3406 41.3885C42.3405 40.6906 42.9062 40.1248 43.6041 40.1247C45.3308 40.1245 47.0374 39.7543 48.609 39.039C50.1805 38.3237 51.5806 37.2799 52.7148 35.978C53.849 34.676 54.691 33.1462 55.1842 31.4914C55.6773 29.8366 55.8102 28.0954 55.5737 26.3849C55.3373 24.6745 54.737 23.0346 53.8134 21.5757C52.8898 20.1168 51.6643 18.8727 50.2194 17.9273C48.7745 16.9819 47.1438 16.3571 45.4371 16.095Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.679 42.652C19.2572 42.6521 15.8857 43.4755 12.8491 45.0527C9.8125 46.6299 7.19995 48.9146 5.23202 51.7138C4.83066 52.2847 4.04247 52.4222 3.47156 52.0208C2.90065 51.6194 2.76321 50.8313 3.16457 50.2603C5.36569 47.1294 8.28781 44.574 11.6842 42.81C15.0807 41.0459 18.8517 40.1249 22.6789 40.1248C26.5061 40.1246 30.2772 41.0453 33.6737 42.8091C37.0703 44.5729 39.9926 47.1281 42.194 50.2589C42.5954 50.8297 42.458 51.6179 41.8871 52.0193C41.3162 52.4207 40.528 52.2834 40.1266 51.7125C38.1585 48.9134 35.5458 46.6289 32.509 45.052C29.4723 43.475 26.1007 42.6519 22.679 42.652Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M53.4359 45.0486C50.3992 43.4716 47.0271 42.6495 43.6054 42.652C42.9075 42.6525 42.3413 42.0872 42.3408 41.3893C42.3403 40.6914 42.9056 40.1253 43.6035 40.1248C47.4314 40.122 51.2035 41.0416 54.6006 42.8058C57.9977 44.57 60.9197 47.1267 63.1191 50.2596C63.5201 50.8308 63.3822 51.6189 62.811 52.0199C62.2398 52.4209 61.4517 52.2829 61.0507 51.7117C59.0846 48.9112 56.4726 46.6257 53.4359 45.0486Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PersonIcon;
