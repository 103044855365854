import React from "react";
import { About1, About2, About3, About4 } from "../Icons";

const About = () => {
  const aboutContent = [
    {
      icon: About1,
      title: `Eliminate Timezone Confusions`,
      contant: `Create an event in seconds and easily share with friends in different timezones. Sveeva will figure out all the time differences for you.`,
    },
    {
      icon: About2,
      title: `Get RSVPs`,
      contant: `See how many friends are coming to your event. Sveeva makes it super easy for them to respond.`,
    },
    {
      icon: About3,
      title: `Easy add to calendar`,
      contant: `No matter how you share your Sveeva event link, and your friends can add all the details to their calendars with a single click so they won’t forget about the event (we’ll also send them an additional reminder if they want).`,
    },
    {
      icon: About4,
      title: `Uh oh!`,
      contant: `Things are out of your control.  It happens. With Sveeva it’s painless and easy to change your event details, and we will make sure that all your attendees are immediately notified about the updates.`,
    },
  ];
  return (
    <>
      <div className="bg-about_bg bg-no-repeat bg-right-top" id="about">
        <div className="container mx-auto px-5 lg:px-20 xl:px-20 2xl:px-32 my-20 md:mb-40">
          <div className="text-[#1E0E62] text-3xl text-center font-bold my-20">
            <h2>About</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-20 md:gap-36 max-w-4xl mx-auto relative">
            <div className="w-3 bg-blue absolute h-full md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2">
              <div className="h-10 w-10 rounded-full bg-blue absolute -right-[14px]"></div>
              <div className="h-10 w-10 rounded-full bg-blue absolute -right-[14px] top-1/4"></div>
              <div className="h-10 w-10 rounded-full bg-blue absolute -right-[14px] top-2/4"></div>
              <div className="h-10 w-10 rounded-full bg-blue absolute -right-[14px] top-3/4"></div>
            </div>
            {aboutContent.map((list, index) => {
              return (
                <div
                  key={index}
                  className={
                    !(index % 2)
                      ? `group border-2 rounded p-7 space-y-4 border-[#EBEAED] hover:border-blue hover:shadow-xl hover:shadow-blue/20 hover:bg-blue ml-7 md:ml-0`
                      : `group border-2 rounded p-7 space-y-4 border-[#EBEAED] hover:border-blue hover:shadow-xl hover:shadow-blue/20 hover:bg-blue md:relative md:top-28 ml-7 md:ml-0`
                  }
                >
                  <div className="grid space-y-5">
                    <div className="bg-blue text-white group-hover:text-black group-hover:bg-white w-fit p-2 rounded">
                      <list.icon className="" />
                    </div>
                    <h2 className="group-hover:text-white font-bold text-[#212121] text-xl max-w-xs">
                      {list.title}
                    </h2>
                  </div>
                  <p className="font-normal group-hover:text-white">
                    {list.contant}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
