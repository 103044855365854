import { RWebShare } from "react-web-share";
import { EventDetailType } from "../../../Interface";
import ShareIcon from "../../Icons/ShareIcon";

export const CustomShare: React.FC<{
  eventDetails: EventDetailType | null;
}> = ({ eventDetails }) => {
  const text = `${eventDetails?.title}: ${eventDetails?.description}`;
  if (navigator.share) {
    return (
      <ShareIcon
        onClick={async () => {
          // setIsShare(true);
          const image =
            eventDetails?.imageUrl || `https://sveeva.com/img/event/404.png`;
          const response = await fetch(image);
          // here image is url/location of image
          const blob = await response.blob();
          const file = new File([blob], "share.jpg", {
            type: blob.type,
          });
          if (navigator.share) {
            await navigator
              .share({
                text,
                url: `http://sveeva.com/?view=rsvp=${eventDetails?._id}`,
                title: "Sveeva",
                files: [file],
              })
              .then(() => console.log("Successful share"))
              .catch((error) => console.log("Error in sharing", error));
          } else {
            console.log(`system does not support sharing files.`);
          }
        }}
        width="30"
        height="30"
        style={{ float: "right" }}
      />
    );
  } else {
    return (
      <RWebShare
        data={{
          text,
          url: `http://sveeva.com/?view=rsvp=${eventDetails?._id}`,
          title: "Sveeva",
        }}
        onClick={() => console.log("shared successfully!")}
      >
        <ShareIcon width="30" height="30" style={{ float: "right" }} />
      </RWebShare>
    );
  }
};
