import React from "react";
import { Outlet } from "react-router-dom";

import { Footer } from "../index";

const Layout = () => {
  return (
    <>
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default Layout;
