import React from "react";

const LocationIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="67"
      viewBox="0 0 66 67"
      fill = "currentColor"
      {...props }
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.1441 20.4064C29.3058 20.4064 26.1942 23.518 26.1942 27.3563C26.1942 31.1946 29.3058 34.3062 33.1441 34.3062C36.9825 34.3062 40.094 31.1946 40.094 27.3563C40.094 23.518 36.9825 20.4064 33.1441 20.4064ZM23.667 27.3563C23.667 22.1222 27.9101 17.8792 33.1441 17.8792C38.3782 17.8792 42.6213 22.1222 42.6213 27.3563C42.6213 32.5904 38.3782 36.8334 33.1441 36.8334C27.9101 36.8334 23.667 32.5904 23.667 27.3563Z"
        fill = "currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.1441 8.08607C28.0333 8.08607 23.1319 10.1163 19.518 13.7302C15.9042 17.344 13.8739 22.2455 13.8739 27.3563C13.8739 36.1415 18.7696 44.064 23.8307 49.8843C26.3459 52.7767 28.8642 55.1065 30.7541 56.7129C31.6981 57.5153 32.483 58.1353 33.0291 58.5527C33.0687 58.5829 33.107 58.6121 33.1441 58.6402C33.1811 58.6121 33.2195 58.5829 33.2591 58.5527C33.8052 58.1353 34.5901 57.5153 35.5341 56.7129C37.424 55.1065 39.9423 52.7767 42.4575 49.8843C47.5186 44.064 52.4143 36.1415 52.4143 27.3563C52.4143 22.2455 50.384 17.344 46.7702 13.7302C43.1563 10.1163 38.2549 8.08607 33.1441 8.08607ZM33.1441 60.2103C32.4195 61.2455 32.4191 61.2453 32.4186 61.2449L32.4133 61.2412L32.3996 61.2316L32.3506 61.1967C32.3083 61.1665 32.2472 61.1225 32.1684 61.065C32.011 60.9501 31.7829 60.7811 31.4945 60.5606C30.9176 60.1197 30.0983 59.4723 29.1173 58.6385C27.1571 56.9723 24.542 54.5537 21.9237 51.5426C16.7179 45.556 11.3467 37.0514 11.3467 27.3563C11.3467 21.5752 13.6432 16.031 17.731 11.9432C21.8188 7.85535 27.3631 5.55884 33.1441 5.55884C38.9251 5.55884 44.4694 7.85535 48.5572 11.9432C52.645 16.031 54.9415 21.5752 54.9415 27.3563C54.9415 37.0514 49.5703 45.556 44.3645 51.5426C41.7462 54.5537 39.1311 56.9723 37.1709 58.6385C36.1899 59.4723 35.3706 60.1197 34.7937 60.5606C34.5052 60.7811 34.2772 60.9501 34.1197 61.065C34.041 61.1225 33.9799 61.1665 33.9376 61.1967L33.8886 61.2316L33.8749 61.2412L33.8696 61.2449C33.8691 61.2453 33.8687 61.2455 33.1441 60.2103ZM33.1441 60.2103L33.8687 61.2455C33.4336 61.5501 32.8545 61.5501 32.4195 61.2455L33.1441 60.2103Z"
        fill = "currentColor"
      />
    </svg>
  );
};

export default LocationIcon;
