import React from "react";
import About from "../Components/Home/About";
import Banner from "../Components/Home/Banner";
import EventCreate from "../Components/Home/EventCreate";
import Events from "../Components/Home/Events";

const Landing = () => {
  return (
    <>
      <Banner />
      <Events />
      <EventCreate />
      <About />
    </>
  );
};

export default Landing;
