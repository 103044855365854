import moment from "moment";
import React, { useEffect } from "react";
// import { CalenderIcon } from "../../Icons";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  title: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  onChange?: (...args: any[]) => any;
  onKeyPress?: (...args: any[]) => any;
}

const InputText: React.FC<InputProps> = (props) => {
  const {
    className,
    title,
    placeholder,
    children,
    onChange,
    onKeyPress,
    value,
    defaultValue,
    ...rest
  } = props;

  useEffect(()=> {
    var today = new Date().toISOString().slice(0, 16);
    // @ts-ignore
  document.getElementsByName(title)[0].min = today;
  },[])

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value);
    }
    return null;
  };

  const handleonKeyPress = (e: any) => {
    if (onKeyPress) {
      onKeyPress(e.key);
    }
    return null;
  };
console.log('value', value);
console.log('defaultValue', defaultValue);

  return (
    <div className="relative grid space-y-2 my-4">
      <label htmlFor={title} className="text-blue font-bold text-xs">
        {title}
        <input
          {...rest}
          id={title}
          name={title}
          type={"datetime-local"}
          autoCapitalize="off"
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={handleOnChange}
          onKeyPress={handleonKeyPress}
          className="w-full border-b-2 text-black focus:outline-none focus:border-b-2 border-[#C1C0C9] focus:border-blue p-2 font-semibold"
        />
        {/* <CalenderIcon
          id={title}
          className="h-5 w-5 text-black absolute right-2 bottom-3 cursor-pointer"
        /> */}
      </label>
    </div>
  );
};

export default InputText;
