import React from "react";

const EventIcon = ({...props}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="276"
      height="184"
      viewBox="0 0 276 184"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.233 17.4898L218.564 31.5968C220.365 32.1953 221.931 33.3461 223.041 34.8857C224.151 36.4252 224.747 38.2753 224.745 40.173V148.665C224.745 155.38 217.678 159.754 211.668 156.744L178.61 140.215C176.101 138.958 173.333 138.304 170.526 138.304C167.72 138.304 164.952 138.958 162.443 140.215L124.387 159.248C121.878 160.505 119.11 161.159 116.303 161.159C113.497 161.159 110.729 160.505 108.219 159.248L67.0729 138.679C65.5714 137.927 64.3086 136.773 63.4259 135.344C62.5433 133.916 62.0756 132.27 62.0752 130.591V28.1174C62.0752 21.9451 68.1211 17.5892 73.9683 19.5411L110.578 31.7415C114.288 32.9796 118.3 32.9796 122.01 31.7415L164.801 17.4806C168.516 16.2515 172.528 16.2515 176.233 17.4898Z"
        fill="white"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M170.522 16.6483V138.38"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M116.299 32.6443V161.154"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M204.24 145.135L225.064 158.578L245.625 145.135L225.064 93.2064L204.24 145.135Z"
        fill="white"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M212.601 139.282L225.52 148.511M225.433 131.692V167.541V131.692Z"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M140.556 139.02L161.38 152.463L181.941 139.02L161.38 87.0912L140.556 139.02Z"
        fill="white"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M148.917 133.166L161.837 142.395M161.749 125.576V161.425V125.576Z"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M41.4868 140.65L62.3107 154.094L82.8712 140.65L62.3107 88.722L41.4868 140.65Z"
        fill="white"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M49.8477 134.797L62.7669 144.026M62.6795 127.207V163.056V127.207Z"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M80.8308 72.3141C89.1135 35.9987 143.057 36.0407 151.298 72.3562C156.133 93.6591 142.847 111.691 131.2 122.845C122.749 130.981 109.379 130.981 100.886 122.845C89.2816 111.691 75.9955 93.6171 80.8308 72.3141Z"
        fill="url(#paint0_linear_206_1958)"
      />
      <path
        d="M105.117 85.4876L113.949 94.4724L131.234 76.9467"
        stroke="white"
        strokeWidth="7.36"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.8096 167.345V155.992"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M49.9053 167.345V159.303"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M36.1865 167.345V159.303"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M84.8018 167.345V155.992"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M91.8979 167.345V159.303"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M78.1792 167.345V159.303"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M144.733 167.345V155.992"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M151.829 167.345V159.303"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M138.11 167.345V159.303"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M188.476 167.345V155.992"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M195.572 167.345V159.303"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <path
        d="M181.854 167.345V159.303"
        stroke="#E3E4EB"
        strokeWidth="3.37333"
      />
      <defs>
        <linearGradient
          id="paint0_linear_206_1958"
          x1="122.431"
          y1="90.4788"
          x2="229.577"
          y2="-21.676"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5956E9" />
          <stop offset="1" stopColor="#5956E9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EventIcon;
