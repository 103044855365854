import React from "react";

const CalenderIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="67"
      height="66"
      viewBox="0 0 67 66"
      fill="currentColor"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2965 11.61C12.8603 11.61 12.5067 11.9636 12.5067 12.3998V53.4674C12.5067 53.9035 12.8603 54.2571 13.2965 54.2571H54.3641C54.8003 54.2571 55.1539 53.9035 55.1539 53.4674V12.3998C55.1539 11.9636 54.8003 11.61 54.3641 11.61H13.2965ZM9.97949 12.3998C9.97949 10.5678 11.4646 9.08276 13.2965 9.08276H54.3641C56.196 9.08276 57.6811 10.5678 57.6811 12.3998V53.4674C57.6811 55.2993 56.196 56.7844 54.3641 56.7844H13.2965C11.4646 56.7844 9.97949 55.2993 9.97949 53.4674V12.3998Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M46.1513 4.97607C46.8492 4.97607 47.4149 5.54182 47.4149 6.23969V14.4532C47.4149 15.1511 46.8492 15.7168 46.1513 15.7168C45.4534 15.7168 44.8877 15.1511 44.8877 14.4532V6.23969C44.8877 5.54182 45.4534 4.97607 46.1513 4.97607Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.5107 4.97607C22.2086 4.97607 22.7743 5.54182 22.7743 6.23969V14.4532C22.7743 15.1511 22.2086 15.7168 21.5107 15.7168C20.8128 15.7168 20.2471 15.1511 20.2471 14.4532V6.23969C20.2471 5.54182 20.8128 4.97607 21.5107 4.97607Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.97949 22.6667C9.97949 21.9688 10.5452 21.4031 11.2431 21.4031H56.4175C57.1153 21.4031 57.6811 21.9688 57.6811 22.6667C57.6811 23.3646 57.1153 23.9303 56.4175 23.9303H11.2431C10.5452 23.9303 9.97949 23.3646 9.97949 22.6667Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.3271 32.9328C23.3271 32.2349 23.8929 31.6692 24.5908 31.6692H31.7776C32.2633 31.6692 32.7061 31.9476 32.9165 32.3854C33.1269 32.8232 33.0678 33.3429 32.7643 33.7222L29.909 37.2913C30.008 37.3366 30.1057 37.3851 30.2021 37.4366C30.9808 37.8528 31.645 38.4546 32.1357 39.1887C32.6264 39.9229 32.9285 40.7667 33.0152 41.6454C33.102 42.5242 32.9707 43.4107 32.633 44.2267C32.2954 45.0426 31.7617 45.7626 31.0794 46.3231C30.397 46.8835 29.5869 47.2671 28.721 47.4397C27.855 47.6124 26.9598 47.5689 26.1146 47.313C25.2695 47.0571 24.5005 46.5968 23.8757 45.9728C23.3819 45.4796 23.3815 44.6795 23.8746 44.1857C24.3678 43.692 25.1679 43.6915 25.6617 44.1846C25.9924 44.515 26.3995 44.7587 26.847 44.8942C27.2944 45.0296 27.7683 45.0527 28.2268 44.9613C28.6852 44.8699 29.1141 44.6668 29.4753 44.3701C29.8366 44.0734 30.1191 43.6922 30.2979 43.2602C30.4767 42.8283 30.5462 42.3589 30.5002 41.8937C30.4543 41.4285 30.2944 40.9818 30.0346 40.5931C29.7748 40.2044 29.4232 39.8858 29.0109 39.6655C28.5986 39.4451 28.1383 39.3299 27.6708 39.3299C27.1851 39.3299 26.7424 39.0514 26.5319 38.6136C26.3215 38.1758 26.3807 37.6562 26.6841 37.2769L29.1485 34.1964H24.5908C23.8929 34.1964 23.3271 33.6307 23.3271 32.9328Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.6094 31.8028C43.0375 32.0169 43.3079 32.4544 43.3079 32.9331V46.28C43.3079 46.9779 42.7422 47.5436 42.0443 47.5436C41.3464 47.5436 40.7807 46.9779 40.7807 46.28V35.4603L38.6957 37.024C38.1374 37.4427 37.3454 37.3296 36.9266 36.7713C36.5079 36.213 36.621 35.421 37.1794 35.0022L41.2861 31.9222C41.669 31.635 42.1813 31.5888 42.6094 31.8028Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CalenderIcon;
