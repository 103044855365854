import React from "react";
import toast from "react-hot-toast";
import Http from "../../../HttpCommon";
import LoadingCircle from "../LoadingCircle";
import { ImageIcon, XIcon } from "../../Icons";
import { ImageUploadType } from "../../../Interface";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  title: string;
  value?: string;
  placeholder?: string;
  onChange?: (...args: any[]) => any;
  onKeyPress?: (...args: any[]) => any;
}

const ImageUpload: React.FC<InputProps> = (props) => {
  const {
    className,
    title,
    placeholder,
    children,
    onChange,
    onKeyPress,
    value,
    ...rest
  } = props;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedImage, setSelectedImage] = React.useState<any>([]);
  const formData = new FormData();
console.log("selectedImage", selectedImage);

  const handleOnChange = (e: ImageUploadType) => {
    if (onChange) {
      onChange(e);
    }
    return null;
  };

  const addImage = (event: any) => {
    setIsLoading(true);
    const reader = new FileReader(),
      filesToDisplay = event.target.files;
    reader.onload = function () {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(filesToDisplay[0]);
    event.preventDefault();
    const files = event.target.files;
    const tempImages = [] as any[];

    for (let index = 0; index < files.length; index++) {
      const file = files[index] as any[];
      const duplicate = false;
      if (!duplicate) tempImages.push(file);
    }

    tempImages.forEach((file: string | Blob) => {
      formData.append("image", file);
    });

    Http.post("/file/image", formData)
      .then(async (response) => {
        handleOnChange(response.data.data);
      })
      .catch((error) => {
        toast.error(`${error.response.data.errors[0].detail}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClearImage = () => {
    setSelectedImage([]);
  };

  React.useEffect(() => {
    if (value !== undefined) {
      setSelectedImage([value]);
    }
  }, [value]);

  return (
    <>
      {selectedImage.length === 0 ? (
        <div className="relative grid space-y-2 my-4">
          <p className="text-blue font-bold text-xs">{title}</p>
          <label
            htmlFor="product_image"
            className="cursor-pointer h-28 w-full rounded-md bg-white border-2 border-blue flex justify-center items-center"
          >
            <ImageIcon className="text-blue" />
            <input
              {...rest}
              type="file"
              accept="image/*"
              className="hidden"
              id="product_image"
              onChange={addImage}
            />
          </label>
        </div>
      ) : (
        <div className="relative grid space-y-2 my-4">
          <div
            className="bg-white border border-gray/50 rounded-full p-2 absolute top-5 -right-4 shadow-2xl cursor-pointer select-none"
            onClick={onClearImage}
          >
            <XIcon className="h-3 w-3 text-blue cursor-pointer" />
          </div>
          <p className="text-blue font-bold text-xs">{title}</p>
          {isLoading ? (
            <div className="h-28 w-full flex justify-center items-center cursor-not-allowed">
              <LoadingCircle className="text-blue h-6 w-6" />
            </div>
          ) : (
            <img
              className="h-28 w-full object-cover rounded-md"
              src={selectedImage}
              alt=""
            />
          )}
        </div>
      )}
    </>
  );
};

export default ImageUpload;
