import React from "react";
import Moment from "react-moment";
import { Button, InputText, Modal, InputRadio } from "../Ui";
import { useSearchParams } from "react-router-dom";
import Http from "../../HttpCommon";
import { EventDetailType, EventListType } from "../../Interface";
import {
  CalenderIcon,
  EventIcon,
  LaptopIcon,
  LinkIcon,
  LocationIcon,
  RightArrow,
} from "../Icons";
import toast from "react-hot-toast";
import * as ics from "ics";
import { EventAttributes } from "ics";
import ShareIcon from "../Icons/ShareIcon";
import { ReactSocialShare } from "../Ui/SocialShare/ReactSocialShare";
import { RWebShare } from "react-web-share";
import { CustomShare } from "../Ui/SocialShare/CustomShare";

export interface EventBoxProps {
  loading: boolean;
  EventList: EventListType | [];
}

const buttonValues = [
  {
    title: "coming",
    value: "coming",
  },
  {
    title: "maybe",
    value: "maybe",
  },
  {
    title: "sorry",
    value: "sorry",
  },
];

const EventBox: React.FC<EventBoxProps> = (props) => {
  const { EventList, loading } = props;
  const [eventEnroll, setEventEnroll] = React.useState("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [eventView, setEventView] = React.useState<boolean>(false);
  const [isReminder, setIsReminder] = React.useState<boolean>(false);
  const [isHourReminder, setIsHourReminder] = React.useState(false);
  const [isDayReminder, setIsDayReminder] = React.useState(false);
  const [isShare, setIsShare] = React.useState(false);
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [eventDetails, setEventDetails] =
    React.useState<EventDetailType | null>(null);
  const [searchParams, setSearchParams] = useSearchParams({
    view: "",
  });
  const activeView = searchParams.get("view");

  const paramsSearch = React.useCallback(() => {
    //CHECK  FOR RSVP ID
    // console.log(activeView?.split("=", 2)[1]);
    if (activeView?.split("=", 2)[1] !== undefined) {
      let eventId = activeView?.split("=", 2)[1];
      //API CALL FOR VIEW EVENT
      Http.get(`/event/fetch/${eventId}`)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            setEventDetails(response.data.data.event);
            setEventView(true);
          }
        })
        .catch((error) => {
          toast.error(`${error.response.data.errors[0].detail}`);
        });
    }

    switch (activeView) {
      case `rsvp`:
        setEventView(true);
        break;
    }
  }, [activeView]);

  //EVENT VIEW OPEN HANDLE
  const eventViewModelOpenHandle = (list: EventDetailType) => {
    setEventDetails(list);
    setSearchParams({ view: `rsvp` });
  };

  //EVENT VIEW CLOSE HANDLE
  const eventViewModelCloseHandle = () => {
    setSearchParams({});
    setEventView(false);
  };

  const onClearData = () => {
    setName("");
    setEmail("");
    setEventEnroll("");
  };

  //EVENT ENROLMENT
  const onEventEroll = () => {
    setIsLoading(true);
    //BODY
    const body = {
      name: name,
      email: email,
      eventId: eventDetails?._id,
      status: eventEnroll,
      isHour: isHourReminder,
      isDay: isDayReminder,
    };
    debugger;
    //API CALL
    Http.post("/eventEnroll/create", body)
      .then((response) => {
        if (response.status === 201) {
          toast.success("RSVP Sent!");
          setIsReminder(false);
          onClearData();
        }
      })
      .catch((error) => {
        toast.error(`${error.response.data.errors[0].detail}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function download(filename: string, text: string | number | boolean) {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/calendar;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  function addToCalender() {
    const time = new Date(eventDetails!.time);
    const event: EventAttributes = {
      start: [time.getFullYear(), time.getMonth() + 1, time.getDate()],
      end: [time.getFullYear(), time.getMonth() + 1, time.getDate()],
      // duration: { hours: 0, minutes: 0 },
      title: eventDetails?.title,
      description: eventDetails?.description,
      location: eventDetails?.eventAddress,
      url: eventDetails?.eventLink,
    };
    // if(eventDetails?.location) {
    //   event.geo = {lon:Number(eventDetails?.location.coordinates[0]), lat:Number(eventDetails?.location.coordinates[1])};
    // }

    ics.createEvent(event, (error: any, value: any) => {
      if (error) {
        console.log(error);
        return;
      }
      download(event.title!, value);
      console.log(value.split("\n"), event);
    });
  }

  const onVisitLink = (link: any) => {
    window.open(link);
  };

  React.useEffect(() => {
    paramsSearch();
  }, [paramsSearch]);

  if (loading) {
    return (
      <div className="w-full animate-pulse">
        <div className="bg-white border-2 border-gray rounded-lg shadow-md hover:border-blue">
          <div className="h-36 w-full rounded bg-black/20" />
          <div className="px-4 py-6 space-y-5">
            <div className="h-3 w-5/6 bg-black/20" />
            <div className="flex gap-4">
              <div className="h-3 w-5 bg-black/20" />
              <div className="h-3 w-5/6 bg-black/20" />
            </div>
            <div className="flex gap-4">
              <div className="h-3 w-5 bg-black/20" />
              <div className="h-3 w-5/6 bg-black/20" />
            </div>
            <div className="h-8 rounded w-full bg-black/20" />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        {EventList.length !== 0 ? (
          <>
            {EventList.map((list, index) => {
              return (
                <div
                  key={index}
                  onClick={() => eventViewModelOpenHandle(list)}
                  className="bg-white border-2 border-gray rounded-lg shadow-md hover:border-blue h-fit hover:cursor-pointer"
                >
                  <div className="h-36 w-auto">
                    <img
                      className="h-full w-full object-cover rounded-tr-lg rounded-tl-lg"
                      src={list.imageUrl || `/img/event/404.png`}
                      alt={list.title}
                    />
                  </div>
                  <div className="p-3 space-y-7">
                    <h2 className="text-xl text-blue font-OpenSans font-bold my-1">
                      {list.title}
                    </h2>
                    <div className="relative flex gap-2 items-center">
                      <CalenderIcon />
                      <div className="absolute ml-6 text-sm">
                        <span className="uppercase font-semibold">
                          <Moment date={list.time} format="ddd" />{" "}
                        </span>{" "}
                        |{" "}
                        <span className="text-gray">
                          <Moment date={list.time} format="MM/DD/YYYY" />{" "}
                        </span>{" "}
                        |{" "}
                        <span>
                          <Moment date={list.time} format="h:mm A" />
                        </span>
                      </div>
                    </div>
                    {list.isPublic ? (
                      <div className="relative flex gap-2 items-center">
                        <LocationIcon />
                        <div className="absolute ml-6 text-sm">
                          <span className="text-gray">
                            {list.eventAddress.slice(0, 25)}... |{" "}
                            {list.dist
                              ? Number(list.dist.calculated / 1609.34).toFixed(
                                  2
                                )
                              : "-"}{" "}
                            miles
                          </span>
                        </div>
                      </div>
                    ) : (
                      <span>
                        {list.eventLink !== "" && (
                          <span className="relative flex gap-2 items-center mt-5">
                            <LaptopIcon />
                            <div className="absolute ml-6 text-sm">
                              <span className="text-blue">
                                {list.eventLink}
                              </span>
                            </div>
                          </span>
                        )}
                      </span>
                    )}
                    <Button
                      width="full"
                      onClick={() => eventViewModelOpenHandle(list)}
                    >
                      More Information
                    </Button>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>
            <div className="border border-gray shadow-2xl h-72 rounded-md w-full absolute py-5">
              <div className="items-center text-center">
                <EventIcon className="mx-auto" />
                <p className="text-sm max-w-xs mx-auto text-gray">
                  Sorry. No upcoming events nearby, just yet.
                </p>
              </div>
            </div>
          </>
        )}

        {/* EVENT VIEW BY ALL PUBLIC USERS */}
        <Modal
          // title="Event View"
          maxWidth="lg"
          show={eventView}
          onClose={eventViewModelCloseHandle}
          showCancel={true}
        >
          <div className="h-64 w-full">
            <img
              style={{ objectFit: "contain" }}
              className="w-full h-full object-cover"
              src={eventDetails?.imageUrl || `/img/event/404.png`}
              alt={eventDetails?.title}
            />
          </div>
          <div className="space-y-5 py-7 px-8">
            <div className="text-sm text-black text-center space-y-1">
              <CustomShare eventDetails={eventDetails} />
              <div>
                <h2 className="text-2xl font-bold text-blue">
                  {eventDetails?.title}
                </h2>
              </div>
              <p className="text-blue font-semibold">
                <Moment date={eventDetails?.time} format="h:mm A" />
              </p>
              <p>
                <span className="font-semibold">
                  <Moment fromNow>{eventDetails?.time}</Moment>
                </span>{" "}
                | <Moment format="MMM D yyyy">{eventDetails?.time}</Moment>
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              {eventDetails?.eventLink !== "" && (
                <div className="space-y-2 text-blue">
                  <h3 className="font-bold text-sm">Event Link</h3>
                  <div className="relative flex text-sm">
                    <LinkIcon className="text-gray h-5 w-5 absolute" />
                    <p
                      className="ml-7 hover:underline cursor-pointer"
                      onClick={() => onVisitLink(eventDetails?.eventLink)}
                    >
                      {eventDetails?.eventLink}
                    </p>
                  </div>
                </div>
              )}
              {eventDetails?.eventAddress !== "" && (
                <div className="space-y-2 text-blue w-full">
                  <h3 className="font-bold text-sm">Location</h3>
                  <div className="relative flex text-sm">
                    <LocationIcon className="text-gray h-5 w-5 absolute" />
                    <p className="ml-7 text-gray">
                      {eventDetails?.eventAddress}
                    </p>
                  </div>
                </div>
              )}
            </div>
            {eventDetails?.description !== "" && (
              <div className="space-y-2 text-blue w-full">
                <h3 className="font-bold text-sm">Description</h3>
                <div className="text-sm">
                  <p className="text-gray">{eventDetails?.description}</p>
                </div>
              </div>
            )}
            {/* {eventDetails?.eventLink !== "" && (
              <div className="space-y-2 text-blue">
                <h3 className="font-bold text-sm">Event Link</h3>
                <div className="relative flex text-sm">
                  <LinkIcon className="text-gray h-5 w-5 absolute" />
                  <p
                    className="ml-7 hover:underline cursor-pointer"
                    onClick={() => onVisitLink(eventDetails?.eventLink)}
                  >
                    {eventDetails?.eventLink}
                  </p>
                </div>
              </div>
            )} */}
          </div>
          <div className="border-t-2 border-gray py-7 px-8">
            <div className="flex gap-5 text-xs">
              <Button outlined width="full" onClick={addToCalender}>
                <CalenderIcon className="mr-2" />
                Add to Calendar
              </Button>
              <Button
                width="full"
                loading={isLoading}
                onClick={() => {
                  eventViewModelCloseHandle();
                  setTimeout(() => {
                    setIsReminder(true);
                  }, 1000);
                }}
              >
                Set RSVP & Reminder <RightArrow className="ml-2" />
              </Button>
            </div>
          </div>
        </Modal>

        {/* EVENT enroll & VIEW BY ALL PUBLIC USERS */}
        <Modal
          // title="Event View"
          maxWidth="lg"
          show={isReminder}
          onClose={() => setIsReminder(false)}
          showCancel={true}
        >
          <div className="relative h-64 w-full">
            <img
              style={{ objectFit: "contain" }}
              className="w-full h-full object-cover"
              src={eventDetails?.imageUrl || `/img/event/404.png`}
              alt={eventDetails?.title}
            />
          </div>
          <div className="absolute top-0 w-full h-64 flex justify-center items-center bg-white/60 backdrop-blur-md space-y-5">
            <div className="text-sm text-black text-center space-y-1">
              <h2 className="text-2xl font-bold text-blue">
                {eventDetails?.title}
              </h2>
              <p className="text-blue font-semibold">
                <Moment date={eventDetails?.time} format="h:mm A" />
              </p>
              <p>
                <span className="font-semibold">
                  <Moment fromNow>{eventDetails?.time}</Moment>
                </span>{" "}
                | <Moment format="MMM D yyyy">{eventDetails?.time}</Moment>
              </p>
            </div>
          </div>

          <div className="py-7 px-8">
            <div className="text-blue w-full lg:w-3/4 mx-auto">
              <h3 className="font-bold text-sm">RSVP</h3>
              <p className="text-xs text-gray">
                Let the organizer know if you can make it.
              </p>
              <ul className="flex justify-between items-center pt-5">
                {buttonValues.map((list, index) => {
                  return (
                    <li className="w-fit" key={index}>
                      <input
                        type="radio"
                        name="answer"
                        id={list.value}
                        value={list.value}
                        className="sr-only peer"
                        onChange={() => setEventEnroll(list.value)}
                      />
                      <label
                        className="px-4 py-2 bg-white capitalize text-xs select-none font-semibold border-2 border-gray rounded cursor-pointer focus:outline-none peer-checked:ring-blue peer-checked:border-transparent peer-checked:bg-blue peer-checked:text-white"
                        htmlFor={list.value}
                      >
                        {list.title}
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="border-t-2 border-gray space-y-6 py-7 px-8">
            <InputText
              title={"Your name"}
              placeholder={"Your name"}
              onChange={(e) => setName(e)}
            />
            <InputText
              title={"Your email"}
              placeholder={"Your email"}
              onChange={(e) => setEmail(e)}
            />
            <div className="block md:flex gap-5 items-center">
              <InputRadio
                title={"1hr in Advance"}
                onChange={(e) => {
                  setIsHourReminder(e);
                }}
              />
              <InputRadio
                title={"1 day in Advance"}
                onChange={(e) => {
                  setIsDayReminder(e);
                }}
              />
            </div>
          </div>

          <div className="pb-7 px-8">
            <Button width="full" loading={isLoading} onClick={onEventEroll}>
              Submit
            </Button>
          </div>
        </Modal>
        {/* SHARE EVENT */}
        <Modal
          // title="Event View"
          maxWidth="lg"
          show={isShare}
          onClose={() => setIsShare(false)}
          showCancel={true}
        >
          <div className="relative h-64 w-full">
            <img
              style={{ objectFit: "contain" }}
              className="w-full h-full object-cover"
              src={eventDetails?.imageUrl || `/img/event/404.png`}
              alt={eventDetails?.title}
            />

            <ReactSocialShare />
          </div>
        </Modal>
      </>
    );
  }
};

export default EventBox;
