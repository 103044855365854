import React from "react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  title: string;
  placeholder?: string;
  value?: string;
  onChange?: (...args: any[]) => any;
  onKeyPress?: (...args: any[]) => any;
}

const Textarea: React.FC<InputProps> = (props) => {
  const { title, placeholder, onChange, value } = props;

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value);
    }
    return null;
  };

  return (
    <div className="grid space-y-2 my-4">
      <label htmlFor={title} className="text-blue font-bold text-xs">
        {title}
        <textarea
          name={title}
          id={title}
          value={value}
          placeholder={placeholder}
          onChange={handleOnChange}
          className="h-28 w-full border-b-2 text-black focus:outline-none focus:border-b-2 border-[#C1C0C9] focus:border-blue p-2 font-semibold"
        ></textarea>
      </label>
    </div>
  );
};

export default Textarea;
