import React from "react";
import { useGeolocated } from "react-geolocated";

const FetchGeolocation = () => {
  const [geolocation, setGeolocation] = React.useState<any | null>(null);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      userDecisionTimeout: 5000,
    });

  React.useEffect(() => {
    if (isGeolocationEnabled) {
      setGeolocation(coords);
    } else if (!isGeolocationAvailable) {
      // toast.error(`Please enable you location from settings.`);
    } else {
      // toast.error(`Please enable you location from settings.`);
    }
    //enable location in browser
    // if (navigator.geolocation) {
    //   navigator.permissions
    //     .query({ name: "geolocation" })
    //     .then(function (result) {
    //       if (result.state === "granted") {
    //         //If granted then you can directly call your function here
    //         navigator.geolocation.getCurrentPosition(success, errors);
    //       } else if (result.state === "prompt") {
    //         navigator.geolocation.getCurrentPosition(success, errors);
    //       } else if (result.state === "denied") {
    //         //If granted then you can directly call your function here
    //         navigator.geolocation.getCurrentPosition(success, errors);
    //       }
    //       // result.onchange = function () {
    //       //   console.log(result.state);
    //       // };
    //       function success(success: any) {
    //         setGeolocation(success);
    //         setIsLocationAllow(true);
    //       }
    //       function errors(err: any) {
    //         setIsLocationAllow(false);
    //         toast.error(`ERROR(${err.code}): ${err.message}`);
    //       }
    //     });
    // } else {
    //   toast.error(`Please enable you location from settings.`);
    // }
  }, [coords, isGeolocationAvailable, isGeolocationEnabled]);

  return [isGeolocationEnabled, geolocation];
};

export default FetchGeolocation;
