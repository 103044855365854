import React from "react";

const RightArrow = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="currentColor"
      {...props}
    >
      <path
        d="M22.9359 8.70711C23.3264 8.31658 23.3264 7.68342 22.9359 7.29289L16.572 0.928932C16.1814 0.538408 15.5483 0.538408 15.1577 0.928932C14.7672 1.31946 14.7672 1.95262 15.1577 2.34315L20.8146 8L15.1577 13.6569C14.7672 14.0474 14.7672 14.6805 15.1577 15.0711C15.5483 15.4616 16.1814 15.4616 16.572 15.0711L22.9359 8.70711ZM0.0288086 9L22.2288 9V7L0.0288086 7L0.0288086 9Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RightArrow;
