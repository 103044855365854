import React from "react";
import { useNavigate } from "react-router-dom";
import { BrandFullIcon } from "../../Icons";
import { Button } from "../../Ui";

const Footer = () => {
  let navigate = useNavigate();

  return (
    <div className="bg-blue text-white px-5 lg:px-20 xl:px-20 2xl:px-32 py-10 relative">
      <div className=" md:absolute top-1/2 right-0 transform -translate-x-1/2 -translate-y-1/2">
        <Button outlined onClick={() => navigate(`/?view=contact`)}>
          Contact Us
        </Button>
      </div>
      <div className="w-full md:w-3/6 mx-auto">
        <BrandFullIcon height={180} width={180} className="mx-auto" />
        <div className="border-t border-white/20 pt-3 text-center grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 mx-auto text-sm">
          <p> &#169; 2022 Sveeva.com</p>
          <p className="cursor-pointer hover:underline">Privacy Policy</p>
          <p className="cursor-pointer hover:underline">Terms of Service</p>
          <p className="cursor-pointer hover:underline">Cookies Settings</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
