import React from "react";

const getInitials = (str: string) => {
  const results = [] as any[];
  const wordArray = str?.split(" ");
  wordArray?.forEach((e) => {
    results?.push(e[0]);
  });
  return results.join("");
};

export interface AvatarProps {
  image: string;
  userName: string;
  isGroup: boolean;
}

const Avatar: React.FC<AvatarProps> = (props) => {
  const { image, userName, isGroup } = props;

  return (
    <div>
      {image === "" || image === undefined ? (
        <div
          className={`${
            isGroup
              ? "bg-cultured flex h-6 w-6 items-center justify-center rounded-full text-xs uppercase ring-2 ring-white sm:h-7 sm:w-7"
              : "bg-cultured flex h-12 w-12 items-center justify-center rounded-full uppercase sm:h-12 sm:w-12"
          }`}
        >
          <div className="flex h-12 w-12 items-center justify-center rounded-full border-2 text-[#2D2D2D] border-gray bg-blue/40 font-bold">
            {getInitials(userName)}
          </div>
        </div>
      ) : (
        <img
          className={`${
            isGroup
              ? "h-6 w-6 rounded-full ring-2 ring-white sm:h-7 sm:w-7"
              : "mr-3 h-10 w-10 rounded-full"
          }`}
          src={`${image}`}
          alt="avatarImg"
        />
      )}
    </div>
  );
};

export default Avatar;
